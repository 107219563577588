<template>
  <div class="page-alert">
    <div class="page-alert-illustration-container">
      <img src="/images/img-erro.svg" class="page-alert-illustration" loading="lazy" />
    </div>
    <p class="page-alert-text">
      <slot>Houve um <strong>erro inesperado </strong>. Tente
      novamente mais tarde.</slot>
    </p>
  </div>
</template>


<script>
export default {
  props: {    
    imgName: {
      type: String,
      required: true,
    }
  },

  data: function () {
    return {};
  }  
}
</script>

<style lang="scss" scoped>
@import "@/styles/_variables.scss";
img {
  max-width: 100%;
}
.page-alert {
  margin-top: 1rem;
  text-align: center;
}
.page-alert-illustration-container {
    margin-bottom: 1rem;

  @media (min-width: $screenLarge) {
    margin-bottom: 2rem;
  }
}
@media (min-width: $screenLarge) {
  .page-alert-illustration {
    width: 429px;
    height: 268px;
  }
}
</style>